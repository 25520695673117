<template>
  <div class="my-ads">
    <div class="row margin-0 justify-content-center">
      <div class="col-md-12 col-lg-10 col-xl-10 col-12 pad-top-25">
        <div class="row margin-0 margin-bottom-15">
          <div class="col pad-0 pad-top-5">
            <h4 class="ad-type"><ion-icon name="image-outline"></ion-icon> My Banners</h4>
          </div>
          <div class="col pad-0 text-right">
            <button class="btn btn-warning btn-sm bold" @click="$router.push('/banner/')">+ <ion-icon name="image-outline"></ion-icon> Banner</button>
          </div>
        </div>
        <div class="row margin-0 list-head">
          <div class="col-md-5 col-12">
            <h5>Label</h5>
          </div>
          <div class="col hidden-xs">
            <h5>Status</h5>
          </div>
          <!-- <div class="col-md-2 col-12 hidden-xs">
            <h5>Supports</h5>
          </div> -->
          <div class="col-md-2 col-4 hidden-xs">
            <h5>Created On</h5>
          </div>
        </div>
        <template v-if="banners && banners.length>0">
          <div v-for="item in banners" :key="item.id" class="row margin-0 list-body">
            <div class="col-md-5 col-12">
              <h5><b>{{item.label}}</b></h5>
              <img :src="item.url" style="max-height:200px;" alt="" class="img-fluid">
            </div>
            <div class="col pad-10">
              <span>Status: <b>{{item.status}}</b></span><br>
              <span>Started at: <b>{{new Date(item.start_on).toLocaleDateString('default', { year: 'numeric', month: 'short', day: 'numeric' })}}</b></span><br>
              <span>Started at: <b>{{new Date(item.ends_on).toLocaleDateString('default', { year: 'numeric', month: 'short', day: 'numeric' })}}</b></span><br>
            </div>
            <div class="col-md-2 col-4 b-top">
              <span>{{new Date(item.created_on).toLocaleDateString('default', { year: 'numeric', month: 'short', day: 'numeric' })}}</span>
            </div>
            <div class="col-md-2 col-4 visible-xs b-top text-center">
              <!-- <span><span @click="$router.push(`/banner/${item.id}/`)" class="pad-left-10 fa fa-pencil"></span> Edit</span> -->
            </div>
          </div>
        </template>
        <template v-else>
          <div class="row margin-0">
            <div class="col-12 text-center pad-30">
              <button class="btn btn-success btn-sm margin-bottom-5" @click="$router.push('/banner/')">+ Ad new Banner</button><br>
              <span class="txt-grey">No Banners added till now</span>
            </div>
          </div>
        </template>
        <br><br>
        <!-- inactive ads -->
        <div class="row margin-0 margin-bottom-10" v-if="false">
          <div class="col pad-0 pad-top-5">
            <h5 class="ad-type">SoldOut Ads</h5>
          </div>
        </div>
        <div class="row margin-0 list-head" v-if="false">
          <div class="col">
            <h5>Ad Title</h5>
          </div>
          <div class="col">
            <h5>Category</h5>
          </div>
          <div class="col">
            <h5>Price</h5>
          </div>
          <div class="col">
            <h5>Status</h5>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
    data() {
        return {
            products: null,
            loading: true
        }
    },
    created() {
        document.title = "My Ads - EBechana"
        this.getProducts();
    },
    computed: {
      ...mapState({
        banners: (state) => state.account.myBanners,
      }),
    },
    methods: {
        getProducts() {
          this.loading = true;
          this.$cs.myproduct.list({
            sort: 'created_on'
          })
          .then(res => {
            this.products = res.data;
            this.loading = false;
          })
        },
    }
}
</script>
<style lang="stylus" scoped>
.my-ads
  min-height 80vh
  background-color #ffffff
  .ad-type
    color #333333
    letter-spacing 0.3px
  .list-head
    background-color #eeeeee
    border-radius 4px
    div[class^="col"], div[class*=" col"]
        padding 15px
        h5
          margin 0px
          font-weight bold
  .list-body
    border-bottom 1px solid #eee
    div[class^="col"], div[class*=" col"]
        padding-top 10px
    .price
      color green
      font-weight 600
    .cat-label
      font-size 0.8em
      color #666666
@media (max-width: 767px)
  .my-ads
    min-height 80vh
    .list-body
      border 1px solid #dddddd
      margin 10px 0px
      padding 5px 0px
      border-radius 4px
      div[class^="col"], div[class*=" col"]
        padding-top 5px
      .b-top
        border-top 1px solid #eee
</style>
